<template>
  <div class="registered">
    <div class="reg-content" :class="regType == 2 ? 'enterprise' : ''">
      <h1 class="reg-content-title">
        {{ regType == 1 ? "个人注册" : "企业注册" }}
      </h1>
      <div class="reg-steps" v-if="regType == 2">
        <span class="reg-step-text now">注册信息</span>
        <span class="reg-step-arrows"></span>
        <span class="reg-step-text">平台审核</span>
        <span class="reg-step-arrows"></span>
        <span class="reg-step-text">审核成功</span>
      </div>
      <div class="from-box">
        <div class="input-box enterprise-name" v-if="regType == 2">
          <input
            type="text"
            class="input-text"
            v-model="regData.company"
            placeholder="请输入企业名称"
          />
        </div>
        <div class="tip-err" v-if="regType == 2 && companyErr">
          {{ companyErr }}
        </div>
        <div class="input-box user-name">
          <input
            type="text"
            class="input-text"
            placeholder="请输入联系人姓名"
            v-model="regData.username"
          />
        </div>
        <div class="tip-err" v-if="nameErr">{{ nameErr }}</div>
        <div class="input-box user-phone">
          <input
            type="number"
            maxlength="11"
            class="input-text"
            placeholder="请输入联系人电话号码"
            v-model="regData.phone"
          />
        </div>
        <div class="tip-err" v-if="phoneErr">{{ phoneErr }}</div>
        <div class="input-box ver-code">
          <input
            type="text"
            class="input-text"
            placeholder="请输入验证码"
            v-model="regData.code"
          />
          <span
            class="get-ver-code"
            :class="this.timer ? 'get' : ''"
            @click="getCode"
            >{{ getCodeBtnText }}</span
          >
        </div>
        <div class="tip-err" v-if="codeErr">{{ codeErr }}</div>
        <template v-if="siteCont.id==99 || siteCont.id==106 && regType == 1">
          <div class="input-box danwei">
            <input
              type="text"
              class="input-text"
              placeholder="请输入单位"
              v-model="regData.remark"
            />
          </div>
          <div v-if="siteCont.id==106 && regType == 1" class="input-box zhiwu">
            <input
              type="text"
              class="input-text"
              placeholder="请输入职务"
              v-model="regData.duty"
            />
          </div>
        </template>
        <div class="input-box password">
          <input
            :type="inputType"
            class="input-text"
            placeholder="请输入密码"
            v-model="regData.password"
          />
          <div
            class="show-pass"
            :class="inputType == 'text' ? 'open' : 'close'"
            @click="showPass(1)"
          ></div>
        </div>
        <div class="input-box password">
          <input
            :type="inputType2"
            class="input-text"
            placeholder="请再次输入密码"
            v-model="regData.confirm_password"
          />
          <div
            class="show-pass"
            :class="inputType2 == 'text' ? 'open' : 'close'"
            @click="showPass(2)"
          ></div>
        </div>
        <div class="tip-err" v-if="passErr">{{ passErr }}</div>
        <button
          class="submit-btn"
          :class="!btnType ? 'disabled' : ''"
          @click="regBtn"
        >
          提交注册
        </button>
        <!-- <div class="agreement">
          <label><input type="checkbox" v-model="agreeCheck"> 我已阅读并同意</label><router-link class="agreement-link" to='/'>隐私政策</router-link>和<router-link to='/' class="agreement-link">《用户协议》</router-link>
        </div> -->
      </div>
      <!-- <div class="switch-reg-type" @click="switchType" v-if="regType!=0">{{regType==1 ? '我要注册企业账户？' : '我要注册个人账户？'}}</div> -->
    </div>

    <!-- 弹出框 -->
    <div class="mask_main" v-if="shenHeState">
      <div class="main_box">
        <p class="close" @click="shenHeState=false"><img src="@/assets/close_code.png" alt="" /></p>
        <p class="title">扫码添加运营专员好友，快速通过账号审核！</p>
        <p class="img"><img src="@/assets/code_img.png" alt="" /></p>
        <p class="tips" @click="$router.push('/index')">返回首页</p>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations } from "vuex";
export default {
  name: "Rgistered",
  data() {
    return {
      timer: "", //倒计时
      getCodeBtnText: "获取验证码",
      agreeCheck: true, //是否同意协议
      regType: 0, //1：个人注册；2企业注册；
      inputType: "password", //密码框类型
      inputType2: "password", //再次输入密码框类型
      regData: {
        //注册信息
        username: "", //用户名
        password: "", //密码
        confirm_password: "", //再次输入密码
        phone: "", //手机号
        code: "", //短信验证码
        company: "", //企业名称
        duty:'',//职务
      },
      companyErr: "", //企业名称错误提示
      phoneErr: "", //手机号错误提示
      nameErr: "", //姓名错误提示
      passErr: "", //密码错误提示
      btnType: false, //按钮是否可用
      nonNull: false, //非空验证
      codeErr: "", //短信验证提示
      shenHeState:false,
      siteCont:{},
    };
  },
  mounted() {
    this.regType = this.$route.params.type;
    this.regData.code=''
    if(localStorage.getItem('siteInfo')){
      this.siteCont = JSON.parse(localStorage.getItem('siteInfo'))
    }
  },
  methods: {
    ...mapMutations(["ShowTipModal", "ShowLoginModal"]), //tip弹窗
    //切换注册类型
    switchType() {
      this.regType = this.regType == 1 ? "2" : "1";
    },
    //显示密码
    showPass(type) {
      if (type == 1) {
        this.inputType = this.inputType == "text" ? "password" : "text";
      } else {
        this.inputType2 = this.inputType2 == "text" ? "password" : "text";
      }
    },
    //手机号验证
    phoneValidation() {
      if (this.regData.phone) {
        let reg = /^0?1[3|4|5|7|8|9][0-9]\d{8}$/;

        if (!reg.test(this.regData.phone)) {
          this.phoneErr = "请填写正确的手机号";
          this.btnType = false;
        } else {
          this.phoneErr = "";

          if (
            this.nonNull == true &&
            this.passErr == "" &&
            this.phoneErr == ""
          ) {
            this.btnType = true;
          }
        }
      }
    },
    //注册按钮
    regBtn() {
      if (this.agreeCheck) {
        let url = "";
        let _this = this;
        if((this.siteCont.id==99 || this.siteCont.id==106) && this.regType == 1){
            if(!this.regData.remark){
              this.ShowTipModal({
                text: '请输入单位',     //提示弹窗内容
              })
              return
            }
            if (this.siteCont.id==106) {
              if(!this.regData.duty){
                this.ShowTipModal({
                  text: '请输入职务',     //提示弹窗内容
                })
                return
              }
            }
        }
        if (this.regType == 1) {
          
          url = `/v1/login/userRegister`;
        } else if ((this.regType = 2)) {
          url = `/v1/login/companyUserRegister`;
        }

        this.$axios.post(url, this.regData).then((res) => {
          if (res.code == 0) {
            this.ShowTipModal({
              text: res.msg, //提示弹窗内容
              fun: function () {
                //回调
                if (_this.regType == 1) {
                  if(res.data.is_open_user_audit==1){
                    this.shenHeState = true
                  }else{
                    this.$router.push('/index');
                  }
                  // _this.$router.go(-2);
                  // _this.ShowLoginModal(); //登录弹窗
                } else if (_this.regType == 2) {
                  _this.$router.push("/index");
                }
              },
            });
          } else {
            //this.passErr = res.msg
            this.ShowTipModal({
              text: res.msg, //提示弹窗内容
            });
          }
        });
      } else {
        this.ShowTipModal({
          text: "请勾选《相关条款》", //提示弹窗内容
          showBtns: true, //是否显示提示弹窗按钮
        });
      }
    },
    //表单验证
    formValidation(newName) {
      if (
        newName.username == "" ||
        newName.phone == "" ||
        newName.password == "" ||
        newName.confirm_password == "" ||
        newName.code == "" ||
        (this.regType == 2 && newName.company == "")
      ) {
        this.nonNull = false;
        this.btnType = false;
        return;
      }

      this.nonNull = true; //非空验证成功

      if (this.passErr == "" && this.phoneErr == "") {
        this.btnType = true;
      }
    },
    //密码验证
    pssValidation() {
      if (
        this.regData.password != "" &&
        this.regData.confirm_password != "" &&
        this.regData.password != this.regData.confirm_password
      ) {
        this.btnType = false;
        this.passErr = "两次密码不一致";
      } else {
        this.passErr = "";

        if (this.nonNull == true && this.passErr == "" && this.phoneErr == "") {
          this.btnType = true;
        }
      }
    },
    getCode() {
      if (this.regData.phone) {
        let url = "";

        if (this.regType == 1) {
          url = `/v1/login/sendSmsCode`;
        } else if (this.regType == 2) {
          url = `/v1/login/companySendSmsCode`;
        }

        this.$axios
          .post(url, {
            phone: this.regData.phone,
          })
          .then((res) => {
            this.codeErr = res.msg;

            if (res.code == 0) {
              let time = 60;

              this.getCodeBtnText = time + "秒后再获取";
              this.timer = setInterval(() => {
                time--;
                if (time == 0) {
                  this.getCodeBtnText = "获取验证码";
                  clearInterval(this.timer);
                  this.timer = "";
                } else {
                  this.getCodeBtnText = time + "秒后再获取";
                }
              }, 1000);
            }
          });
      } else {
        this.phoneErr = "请填写手机号码";
      }
    },
  },
  watch: {
    //监听表单
    regData: {
      handler(newName, oldName) {
        //表单验证
        this.formValidation(newName, oldName);
      },
      immediate: true,
      deep: true, //为true，表示深度监听，这时候就能监测到a值变化
    },
    "regData.confirm_password": {
      handler(newName, oldName) {
        //密码验证
        this.pssValidation(newName, oldName);
      },
      immediate: true,
      deep: true, //为true，表示深度监听，这时候就能监测到a值变化
    },
    "regData.password": {
      handler(newName, oldName) {
        //密码验证
        this.pssValidation(newName, oldName);
      },
      immediate: true,
      deep: true, //为true，表示深度监听，这时候就能监测到a值变化
    },
    "regData.phone": {
      handler(newPhone, oldPhone) {
        if (newPhone.length >= 11) {
          //密码验证
          this.phoneValidation(newPhone, oldPhone);
        }
      },
      immediate: true,
      deep: true, //为true，表示深度监听，这时候就能监测到a值变化
    },
  },
};
</script>
<style scoped lang="scss">
.registered {
  padding: 64px 0 88px;
  background-color: #f7f7f7;
}
.reg-content {
  box-sizing: border-box;
  width: 1200px;
  padding: 74px 116px 89px;
  background-color: #fff;
  margin: 0 auto;
}
.reg-content-title {
  font-size: 24px;
  font-weight: 900;
  color: #333;
  text-align: center;
}
.reg-steps {
  display: flex;
  justify-content: center;
  padding: 54px 0 90px;
}
.reg-step-text {
  font-size: 14px;
  color: #666;
  display: flex;
  align-items: center;
  &::before {
    content: "";
    display: block;
    box-sizing: border-box;
    width: 16px;
    height: 16px;
    border: 2px solid #d8d8d8;
    border-radius: 50%;
    //background: url(~@/assets/selected-ico.png) no-repeat;
    margin-right: 10px;
  }
  &.now {
    color: #254ed4;
    &::before {
      border: none;
      background: url(~@/assets/selected-ico.png) no-repeat;
    }
  }
}
.reg-step-arrows {
  width: 46px;
  height: 16px;
  background: url(~@/assets/arrows-steps.png) no-repeat;
  background-size: 100%;
  margin: 0 21px;
}
.reg-type-list {
  display: flex;
  justify-content: space-between;
}
.reg-type {
  box-sizing: border-box;
  width: 270px;
  padding: 33px 23px 35px 25px;
  background-color: #fff;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.14);
  border-radius: 6px;
  text-align: center;
}
.head-portrait {
  width: 88px;
  height: 88px;
  border-radius: 50%;
  margin: 0 auto 14px;
  &.personal {
    background: url(~@/assets/head-default-personal.png) no-repeat;
    background-size: 100% 100%;
  }
  &.enterprise {
    background: url(~@/assets/head-default-enterprise.png) no-repeat;
    background-size: 100% 100%;
  }
  &.lecturer {
    background: url(~@/assets/head-default-lecturer.png) no-repeat;
    background-size: 100% 100%;
  }
}
.reg-title {
  font-size: 18px;
  font-weight: 800;
  color: #333;
  text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.14);
  margin-bottom: 14px;
}
.reg-introduce {
  font-size: 14px;
  color: #999;
  line-height: 19px;
  text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.14);
  margin-bottom: 24px;
}
.reg-btn {
  width: 100%;
  height: 40px;
  line-height: 40px;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.14);
  border-radius: 8px;
  font-size: 16px;
  color: #fff;
  text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.14);
  &.blue {
    background-color: #254ed4;
    cursor: pointer;
  }
  &.greey {
    background-color: #999;
  }
}
.from-box {
  width: 432px;
  padding-top: 50px;
}
.submit-btn {
  width: 100%;
  height: 44px;
  border-radius: 6px;
  font-size: 16px;
  margin-top: 40px;
  cursor: pointer;
  background-color: #254ed4;
  color: #fff;
  &.disabled {
    background: #999;
    color: #fff;
  }
}
.agreement {
  display: flex;
  align-items: center;
  padding-top: 14px;
  font-size: 12px;
  color: #666;
  text-shadow: 0px 0px 40px rgba(0, 0, 0, 0.3);
  input {
    margin-right: 4px;
    cursor: pointer;
  }
  .agreement-link {
    color: #254ed4;
  }
  label {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}
.switch-reg-type {
  margin-top: 44px;
  font-size: 12px;
  color: #666;
  text-align: center;
  cursor: pointer;
}
.reg-content.enterprise {
  .reg-title {
    margin-bottom: 54px;
  }
  .from-box {
    padding-top: 0;
  }
}
.user-name {
  padding-top: 0;
}

// 审核弹出框
.mask_main {
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  .main_box {
    width: 400px;
    height: 320px;
    background: #ffffff;
    border-radius: 5px 5px 5px 5px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    p.close {
      margin: 20px;
      display: flex;
      justify-content: flex-end;
    }
    p.title {
      text-align: center;
      margin-top: 32px;
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
    }
    p.img{
      width: 130px;
      height: 130px;
      margin: 20px auto;
    }
    p.tips{
      text-align: center;
      margin-top: 8px;
      font-size: 12px;
font-family: PingFang SC-Regular, PingFang SC;
font-weight: 400;
color: #999999;
cursor: pointer;

    }
  }
}
</style>